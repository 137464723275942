
export const callApi = async (endpoint, token, apiOrigin) => {
  const result = {
    loading: true,
    data: null,
    error: null
  }

  return fetch(
    `${apiOrigin}${endpoint}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then(data => result.data = data)
    .catch(error => result.error = error)
    .finally(() => result.loading = false)
    .then(() => result)
}
