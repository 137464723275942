import { getConfig } from '../config'
import { useApiCallback } from './useApiCallback'

const { ssoApiOrigin } = getConfig()

export const useClients = () => {
  const { data, error } = useApiCallback('/clients', ssoApiOrigin)

  return {
    clients: (data?.clients || [])
      .map((c, key) => ({
        ...c,
        key
      })),
    error
  }
}
