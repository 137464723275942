import {
  Typography,
  TextField,
  Stack,
  Autocomplete
} from '@mui/material'
import PropTypes from 'prop-types'

export const LabelDescriptionSelect = ({
  name,
  value,
  options,
  helperText,
  handleChange,
  keySelector,
  labelSelector,
  descriptionSelector
}) => <Autocomplete
  options={options}
  getOptionLabel={labelSelector}
  value={value}
  onChange={(_event, value) => handleChange(name, value)}
  isOptionEqualToValue={(option, value) => {
    return option.name === value.name
  }}
  renderOption={(props, value) => {
    return (
      <li {...props} key={keySelector(value)}>
        <Stack>
          <Typography variant='subtitle1'>{labelSelector(value)}</Typography>
          <Typography variant='caption'>{descriptionSelector(value)}</Typography>
        </Stack>
      </li>)
  }}
  renderInput={(params) => (
    <TextField {...params} name='client' value={value} helperText={helperText} />
  )}
/>

LabelDescriptionSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  helperText: PropTypes.string,
  handleChange: PropTypes.func,
  keySelector: PropTypes.func,
  labelSelector: PropTypes.func,
  descriptionSelector: PropTypes.func
}
